import {
  LENGTH_3,
  LENGTH_4,
  LENGTH_5,
  LENGTH_6,
  LENGTH_7,
} from "../config/words";

export function isValidWord(word) {
  const dict = WORDS[word.length];

  // Binary search
  let left = 0;
  let right = dict.length - 1;
  while (left <= right) {
    const middle = left + Math.floor((right - left) / 2);
    if (dict[middle] < word) {
      left = middle + 1;
    } else if (dict[middle] > word) {
      right = middle - 1;
    } else {
      return true;
    }
  }
  return false;
}

const WORDS = {
  3: LENGTH_3,
  4: LENGTH_4,
  5: LENGTH_5,
  6: LENGTH_6,
  7: LENGTH_7,
};
